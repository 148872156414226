import React from "react";
import { timeFormat } from "d3-time-format";
import { XYPlot, XAxis, YAxis, VerticalBarSeries, Hint } from "react-vis";
import { Card, Tooltip } from "../../styles";
import { abundanceDW, colors, complexes } from "../../helpers";
import ChartTitle from "../ChartTitle";
import { Calendar, Filter, LineChart, PieChart } from "../icons";
import Tutorial from "../Tutorial";

const chartMargin = {
  left: 50,
  right: 10,
  top: 30,
  bottom: 80,
};

const formatDate = timeFormat("%b %e, %Y");
const lineAnimation = { damping: 42, stiffness: 450 };
const axisAnimation = { damping: 20, stiffness: 450 };

/**
 *
 * @param {date} endDate
 * @param {number} intervalLength
 * @returns {date} - formatted date
 **/
const getStartDate = (endDate, intervalLength) => {
  let startDate = new Date(endDate);
  let daysInInterval = 6;
  let weeksCounted = 0;
  while (weeksCounted < intervalLength) {
    weeksCounted += 1;
    daysInInterval += 7;
  }
  startDate.setDate(startDate.getDate() - daysInInterval);
  return formatDate(startDate);
};

class AgencyChart extends React.PureComponent {
  state = {
    hint: null,
    chartData: {
      tempSpecies: [
        { x: 1, y: 1 },
        { x: 2, y: 2 },
      ],
    },
    speciesColor: {
      tempSpecies: "black",
    },
    yMax: 10,
  };

  componentDidUpdate(prevProps) {
    let changed = false;

    if (
      prevProps.species.length !== this.props.species.length ||
      prevProps.year !== this.props.year ||
      prevProps.trap !== this.props.trap ||
      prevProps.agency !== this.props.agency ||
      prevProps.intervalLength !== this.props.intervalLength ||
      prevProps.chartType !== this.props.chartType ||
      JSON.stringify(prevProps.data) !== JSON.stringify(this.props.data) ||
      JSON.stringify(prevProps.abundanceRates) !==
        JSON.stringify(this.props.abundanceRates)
    ) {
      changed = true;
    }

    for (let i = 0; i < prevProps.species.length; i += 1) {
      if (!this.props.species.includes(prevProps.species[i])) {
        changed = true;
      }
    }

    if (changed) {
      this.formatData();
    }
  }

  handleComplexes = () => {
    const { abundanceRates, species } = this.props;
    if (!complexes.includes(species[0])) {
      // If it's not a complex, it's in the wrong place
      return false;
    }
    let releventSpeciesColors = abundanceRates
      .slice(0, 9)
      .reduce((all, item) => {
        all[item.species] = item.color;
        return all;
      }, {});
    let releventSpeciesList = Object.keys(releventSpeciesColors);
    let speciesDataSeries = this.getComplexDataSeries(releventSpeciesList);
    let yMax = this.getYMax();

    this.setState({
      chartData: speciesDataSeries,
      speciesColor: releventSpeciesColors,
      yMax,
    });
  };

  transformDataToBars = () => {
    const { chartData, speciesColor } = this.state;
    let bars = Object.keys(chartData).map((species) => {
      return (
        <VerticalBarSeries
          data={chartData[species]}
          barWidth={0.9}
          fill={speciesColor[species] || "gray"}
          animation={lineAnimation}
          onValueMouseOut={this.removeHint}
          onValueMouseOver={this.updateHint}
          key={species}
        />
      );
    });
    return bars;
  };

  getYMax = () => {
    const { data, species, trap, weeksInYear } = this.props;
    let yMax = 0;
    const currentSpecies = species[0];
    for (let week = 1; week < weeksInYear; week += 1) {
      if (data && data?.[week]?.[trap]?.[currentSpecies]) {
        yMax = Math.max(data[week][trap][currentSpecies], yMax);
      }
    }
    yMax = Math.ceil(yMax / 10) * 10 || 10;
    return yMax;
  };

  // returns such that {species:[{x,y}, {x,y}...]}
  getComplexDataSeries = (releventSpeciesList) => {
    const { data, trap, weeksInYear, year } = this.props;
    const speciesDataSeries = {};
    releventSpeciesList.forEach((species) => (speciesDataSeries[species] = []));
    speciesDataSeries["All others"] = [];

    for (let week = 1; week < weeksInYear; week += 1) {
      const x = new Date(abundanceDW[year][week]).getTime();
      if (
        data?.[week]?.[trap]?.["All Species"] // If it's a 0, just skip to the other one
      ) {
        // if there is such data, use it
        let currentWeekData = data[week][trap];
        const totalInWeek = currentWeekData["All Species"] || 0;
        let currentWeekOthers = 0;
        const currentWeekSpecies = Object.keys(currentWeekData); // releventSpeciesList;
        for (let i = 0; i < currentWeekSpecies.length; i += 1) {
          let species = currentWeekSpecies[i];
          if (species === "All others" || species === "speciesPercentages")
            continue; // In case of shenanigans
          if (speciesDataSeries[species] || speciesDataSeries[species] === 0) {
            const bar = {
              x,
              y: currentWeekData["speciesPercentages"][species]
                ? currentWeekData["speciesPercentages"][species] * totalInWeek
                : 0,
              totalInWeek,
              percentOfTotal: currentWeekData["speciesPercentages"][species]
                ? currentWeekData["speciesPercentages"][species]
                : 0,
              species,
            };
            speciesDataSeries[species].push(bar);
          } else {
            if (!complexes.includes(species))
              currentWeekOthers +=
                +currentWeekData["speciesPercentages"][species];
          }
        }
        // These are still series so we complete them here if they weren't done already
        releventSpeciesList.forEach((specie) => {
          if (currentWeekData[specie] === undefined) {
            const bar = {
              x,
              y: 0,
              totalInWeek,
              percentOfTotal: 0,
              species: specie,
            };
            speciesDataSeries[specie].push(bar);
          }
        });
        const allOthersBar = {
          x,
          y: currentWeekOthers ? currentWeekOthers * totalInWeek : 0,
          totalInWeek,
          percentOfTotal: currentWeekOthers ? currentWeekOthers : 0,
          species: "All others",
        };
        speciesDataSeries["All others"].push(allOthersBar);
      } else {
        const y = 0;
        speciesDataSeries["All others"].push({ x, y });
        for (let i = 0; i < releventSpeciesList.length; i += 1) {
          const species = releventSpeciesList[i];
          speciesDataSeries[species].push({ x, y, totalInWeek: 0 });
        }
      }
    }

    return speciesDataSeries;
  };

  formatData = () => {
    const { species, trap, weeksInYear, year, data, abundanceRates } =
      this.props;

    const currentSpecies = species[0];
    if (currentSpecies === "All Species") {
      this.handleComplexes();
    } else {
      let chartData = [];
      for (let week = 1; week < weeksInYear; week++) {
        const x = new Date(abundanceDW[year][week]).getTime();
        const y =
          data && data?.[week]?.[trap]?.[currentSpecies]
            ? data[week][trap][currentSpecies]
            : 0; // if there is no nested datapoint for specified params, y = 0

        chartData.push({ x, y });
      }
      let currentSpeciesColor;
      let included = abundanceRates.find((d) => d.species === currentSpecies);
      currentSpeciesColor = included ? included.color : "black";
      const speciesColor = { currentSpecies: currentSpeciesColor };
      const yMax = this.getYMax();
      chartData = { currentSpecies: chartData };
      this.setState({ chartData, speciesColor, yMax });
    }
  };

  updateHint = (e) => {
    this.setState((prevState) => {
      const { hint } = prevState;
      if (
        !e ||
        (e?.x === hint?.x &&
          e?.y === hint?.y &&
          e?.totalInWeek === hint?.totalInWeek &&
          e?.species === hint?.species &&
          e?.y0 === hint?.y0)
      ) {
        // If nothing has changed, don't do anything
        return null;
      }
      return { hint: e };
    });
  };

  removeHint = () => {
    this.setState({ hint: null });
  };

  renderTutorial() {
    const instructions = [
      {
        icon: <Calendar color={colors["light-blue"]} size={32} />,
        text: "Change the disease week using the slider above the map. Change the year in the dropdown above",
      },
      {
        icon: <Filter color={colors["gray"]} size={32} />,
        text: "Only species that are present that year in the specified region will be available",
      },
      {
        icon: <LineChart color={colors["green"]} size={32} />,
        text: "Click an agency or zone on the map for detailed information",
      },
      {
        icon: <PieChart color="black" size={32} />,
        text: "See the pie chart below for a breakdown by species of vectors in the specified region",
      },
    ];

    return (
      <Tutorial
        title="USAPI Abundance Visualizations"
        description="This map shows abundances of different female mosquitoes and other vectors by the agency that collected them or the zone in which they were collected."
        instructions={instructions}
        loading={this.props.loading}
      />
    );
  }

  render() {
    const {
      agency,
      chartType,
      clearAgency,
      intervalLength,
      species,
      width,
      year,
      zone,
    } = this.props;
    if (!chartType) {
      return this.renderTutorial();
    }
    const { hint, yMax } = this.state;

    //const yMax = Math.max(...Object.values(chartData.map((d) => d.y)));
    const plotProps = {
      height: 300,
      width,
      xType: "time",
      margin: chartMargin,
      onMouseLeave: this.removeHint,
      stackBy: "y",
      yDomain: [0, yMax],
      stroke: "",
    };

    let bars = this.transformDataToBars();

    return (
      <Card style={{ display: "block", marginBottom: "10px" }}>
        <ChartTitle
          city={`${
            chartType === "agency" ? agency : chartType === "zone" ? zone : "PI"
          } (${year})`}
          units={species.join("/") + " per trap night"}
          action={clearAgency}
        />
        <div id="chartContainer">
          <XYPlot {...plotProps}>
            <XAxis tickLabelAngle={-45} animation={axisAnimation} />
            <YAxis animation={axisAnimation} />
            {bars}
            {hint && (
              <Hint value={hint}>
                <Tooltip style={{ textAlign: "left" }}>
                  <div>{`${+intervalLength + 1} week period from ${getStartDate(
                    formatDate(new Date(hint.x)),
                    intervalLength
                  )} to ${formatDate(new Date(hint.x))}`}</div>
                  <div>
                    {species.join("/") === "All Species" ? "Total m" : "M"}
                    osq/trap night:{" "}
                    {hint.totalInWeek
                      ? hint.totalInWeek.toFixed(2)
                      : hint.y.toFixed(2)}
                  </div>
                  {species.join("/") === "All Species" && (
                    <div>
                      {hint.species} mosq/trap night:{" "}
                      {(hint.totalInWeek * +hint.percentOfTotal).toFixed(2)}
                    </div>
                  )}
                </Tooltip>
              </Hint>
            )}
          </XYPlot>
        </div>
      </Card>
    );
  }
}

export default AgencyChart;
