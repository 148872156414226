import axios from "axios";
import polyUtil from "polyline-encoded";
import saveAs from "file-saver";
import watermark from "watermarkjs";
import arboLegend from "./constants/ArboLegend.png";
import aegyptiLegend from "./constants/AegyptiLegend.png";
import albopictusLegend from "./constants/AlbopictusLegend.png";
import notoscriptusLegend from "./constants/NotoscriptusLegend.png";
import zikaLegend from "./constants/ZikaLegend.png";
import { diseaseWeeks } from "./constants/diseaseWeeks";
import logo from "./constants/VectorSurv_Icon.svg";
import Cookies from "universal-cookie";
import queryString from "query-string";

const legends = {
  arboLegend,
  aegyptiLegend,
  albopictusLegend,
  notoscriptusLegend,
  zikaLegend,
};

export const downloadSVG = (props) => {
  props = props || {};
  const svg = document.querySelector("#chartContainer svg");
  const height = props.height || 2400;
  const width = props.width || 3200;
  const filename = props.filename || "VectorSurvChart.png";
  const watermarkPos = [props.x || 1800, props.y || 220];
  const legendPos = [props.legendX || 200, props.legendY || 2100];
  // react-vis charts come back with transparent background and can cause problems. just add white background for exported img
  // also font sizes are tiny on export, so lets explicitly set them here
  let svgString = translateSVGToString(svg).replace(
    "</style>",
    ".rv-xy-plot__inner{background-color:white}.rv-xy-plot__axis__tick__text{font-size:12px}.rv-xy-plot__axis__title{font-size:16px}</style>"
  );
  convertSVGtoImage(svgString, width, height, save);
  const legend = legends[props.legend] || null;

  function save(dataBlob) {
    const vectorsurvx = () => watermarkPos[0];
    const vectorsurvy = () => watermarkPos[1];
    const legendx = () => legendPos[0];
    const legendy = () => legendPos[1];
    if (legend) {
      watermark([dataBlob, legend])
        .blob(watermark.image.atPos(legendx, legendy, 1))
        .render()
        .blob(
          watermark.text.atPos(
            vectorsurvx,
            vectorsurvy,
            "maps.VectorSurv.org",
            "100px Roboto",
            colors["dark-blue"],
            0.2
          )
        )
        .then((img) => {
          saveAs(img, filename);
        });
    } else {
      saveAs(dataBlob, filename);
    }
  }
};

export function translateSVGToString(svgNode) {
  svgNode.setAttribute("xlink", "http://www.w3.org/1999/xlink");
  const cssStyleText = getCSSStyles(svgNode);
  appendCSS(cssStyleText, svgNode);

  const serializer = new XMLSerializer();
  let svgString = serializer.serializeToString(svgNode);
  svgString = svgString.replace(/(\w+)?:?xlink=/g, "xmlns:xlink="); // Fix root xlink without namespace
  svgString = svgString.replace(/NS\d+:href/g, "xlink:href"); // Safari NS namespace fix

  return svgString;

  function getCSSStyles(parentElement) {
    const selectorTextArr = [];

    // Add Parent element Id and Classes to the list
    selectorTextArr.push("#" + parentElement.id);
    for (let c = 0; c < parentElement.classList.length; c++)
      if (!contains("." + parentElement.classList[c], selectorTextArr))
        selectorTextArr.push("." + parentElement.classList[c]);
    // Add Children element Ids and Classes to the list
    const nodes = parentElement.getElementsByTagName("*");
    for (let i = 0; i < nodes.length; i++) {
      const id = nodes[i].id;
      if (!contains("#" + id, selectorTextArr)) selectorTextArr.push("#" + id);

      const classes = nodes[i].classList;
      for (let c = 0; c < classes.length; c++)
        if (!contains("." + classes[c], selectorTextArr))
          selectorTextArr.push("." + classes[c]);
    }

    // Extract CSS Rules
    let extractedCSSText = "";
    for (let i = 0; i < document.styleSheets.length; i++) {
      const s = document.styleSheets[i];

      try {
        if (!s.cssRules) continue;
      } catch (e) {
        if (e.name !== "SecurityError") throw e; // for Firefox
        continue;
      }

      const cssRules = s.cssRules;
      for (let r = 0; r < cssRules.length; r++) {
        if (contains(cssRules[r].selectorText, selectorTextArr))
          extractedCSSText += cssRules[r].cssText;
      }
    }

    return extractedCSSText;

    function contains(str, arr) {
      return arr.indexOf(str) === -1 ? false : true;
    }
  }

  function appendCSS(cssText, element) {
    const styleElement = document.createElement("style");
    styleElement.setAttribute("type", "text/css");
    styleElement.innerHTML = cssText;
    const refNode = element.hasChildNodes() ? element.children[0] : null;
    element.insertBefore(styleElement, refNode);
  }
}

export function convertSVGtoImage(svgString, width, height, callback) {
  const imgsrc =
    "data:image/svg+xml;base64," +
    btoa(unescape(encodeURIComponent(svgString))); // Convert SVG string to data URL

  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");

  canvas.width = width;
  canvas.height = height;

  const newImage = new Image();
  newImage.onload = function () {
    context.clearRect(0, 0, width, height);
    context.drawImage(newImage, 0, 0, width, height);

    canvas.toBlob(function (blob) {
      const filesize = Math.round(blob.length / 1024) + " KB";
      if (callback) callback(blob, filesize);
    });
  };

  newImage.src = imgsrc;
}

export const downloadMap = (map) => {
  map.getCanvas().toBlob((blob) => {
    watermark([blob])
      .blob(
        watermark.text.lowerLeft(
          "maps.VectorSurv.org",
          "60px Roboto",
          colors["dark-blue"],
          0.2
        )
      )
      .then((blob) => {
        saveAs(blob, "map.png");
      });
  });
};

export const arboToFull = {
  WNVPools: "West Nile virus mosquitoes",
  WNVSentinels: "West Nile virus sentinels",
  WNVBirds: "West Nile virus dead birds",
  SLEVPools: "St. Louis Encephalitis virus mosquitoes",
  SLEVSentinels: "St. Louis Encephalitis virus sentinels",
  WEEVPools: "Western Equine Encephalitis virus mosquitoes",
  WEEVSentinels: "Western Equine Encephalitis virus sentinels",
  LACVPools: "La Crosse virus mosquitoes",
  JCVPools: "Jamestown Canyon virus mosquitoes",
  EEEVPools: "Eastern Equine Encephalitis virus mosquitoes",
  FLAVPools: "Flanders virus mosquitoes",
};

export const colors = {
  red: "#f44336",
  "dark-red": "#c20f02",
  "light-red": "#ef9a9a",
  orange: "#ff9800",
  "light-orange": "#ffb74d",
  "dark-orange": "#f57c00",
  yellow: "#ffeb3b",
  "light-yellow": "#fff176",
  "dark-yellow": "#fbc02d",
  green: "#4caf50",
  "light-green": "#8bc34a",
  "dark-green": "#388e3c",
  blue: "#2196f3",
  "light-blue": "#6ec6ff",
  "dark-blue": "#1565c0",
  purple: "#9c27b0",
  "light-purple": "#ffc4ff",
  "dark-purple": "#673ab7",
  gray: "#9e9e9e",
  cyan: "#62efff",
  pink: "#e91e63",
  indigo: "#3f51b5",
  teal: "#009688",
  amber: "#ffc107",
  black: "#212121",
  "dark-gray": "#424242",
  "gray-blue": "#8d99a5",
  "uc-gray": "#7C7E7F",
  "uc-blue": "#1295D8",
  "uc-gold": "#FFB511",
  "warm-gray": "#DBD5CD",
  burgundy: "#800020",
  "aggie-blue": "#022851",
  "aggie-gold": "#FFBF00",
  "invasive-growth": "#7DA8A3",
  "vectorsurv-navy": "#003366",
  "vectorsurv-blue": "#0073E6",
  "vectorsurv-green": "#3CCE3C",
  "vectorsurv-orange": "#F56200",
  "vectorsurv-light-blue": "#B5DCF4",
  surveillance: "#adadad",
  nonSurveillance: "#e9e9e9",
};

export const invasiveColors = {
  invasiveSpecies: colors["red"],
  surveillance: colors["surveillance"],
  nonSurveillance: colors["nonSurveillance"],
  totalCollections: colors["warm-gray"],
  growthLine: colors["invasive-growth"],
  highlightColor: colors["vectorsurv-green"],
};

export const bbaColors = {
  0: "#b8b8b8",
  1: "#2196f3",
  2: "#4caf50",
  3: "#3f51b5",
  4: "#f44336",
  5: "#9c27b0",
  6: "#009688",
  7: "#ffeb3b",
  8: "#62efff",
  9: "#ff9800",
  10: "#e91e63",
  11: "#388e3c",
  12: "#c20f02",
  13: "#673ab7",
  14: "#f57c00",
  15: "#1565c0",
  16: "#fbc02d",
  17: "#ffc4ff",
  18: "#ffc107",
  19: "#6ec6ff",
  20: "#ef9a9a",
  21: "#ffb74d",
  22: "#fff176",
};

export const zikaDengueColors = {
  xLow: colors["vectorsurv-navy"],
  vLow: colors["light-blue"],
  low: colors["light-red"],
  moderate: colors["red"],
  highlightColor: colors["vectorsurv-green"],
  surveillance: colors["surveillance"],
};

export const arboColors = {
  WNVPools: colors["pink"],
  WNVSentinels: colors["blue"],
  WNVBirds: colors["green"],
  SLEVPools: colors["orange"],
  SLEVSentinels: colors["purple"],
  WEEVSentinels: colors["red"],
  WEEVPools: colors["indigo"],
  LACVPools: colors["dark-purple"],
  JCVPools: colors["dark-orange"],
  EEEVPools: colors["dark-blue"],
  FLAVPools: colors["light-green"],
};

export const trapTypes = [
  "All traps",
  "New Jersey light trap",
  "Carbon dioxide baited trap",
  "Gravid trap",
  "Mosquito magnet trap",
  "BG Sentinel",
  "Resting box collection",
  "CDC Autocidal Gravid Ovitrap (counts)",
];

export const populations = [
  "All areas",
  "Rural",
  "Suburban",
  "Urban",
  "Unspecified",
];

export const arboDatasets = [
  "WNVPools",
  "WNVSentinels",
  "WNVBirds",
  "SLEVPools",
  "SLEVSentinels",
  "WEEVPools",
  "WEEVSentinels",
  "LACVPools",
  "JCVPools",
  "EEEVPools",
  "FLAVPools",
];

export const translateArbo = {
  WNVPools: "WNV Mosquitoes",
  WNVSentinels: "WNV Sentinels",
  WNVBirds: "WNV Dead Birds",
  SLEVPools: "SLEV Mosquitoes",
  SLEVSentinels: "SLEV Sentinels",
  WEEVPools: "WEEV Mosquitoes",
  WEEVSentinels: "WEEV Sentinels",
  LACVPools: "LACV Mosquitoes",
  JCVPools: "JCV Mosquitoes",
  EEEVPools: "EEEV Mosquitoes",
  FLAVPools: "FLAV Mosquitoes",
};

export const quartersSince = (year) => {
  let dates = [new Date(`${year}-01-02`)];
  const today = new Date();

  while (new Date(dates[dates.length - 1]) < today) {
    const oldDate = new Date(dates[dates.length - 1]);
    const newDate = new Date(oldDate.setMonth(oldDate.getMonth() + 3));
    dates.push(newDate);
  }

  return dates;
};

export const quartersSince2003 = () => {
  let dates = [
    new Date(`Wed Jan 01 2003 16:00:00 GMT-0800 (Pacific Standard Time)`),
  ];
  const today = new Date();

  while (new Date(dates[dates.length - 1]) < today) {
    const oldDate = new Date(dates[dates.length - 1]);
    const newDate = new Date(oldDate.setMonth(oldDate.getMonth() + 3));
    dates.push(newDate);
  }

  return dates;
};

/**
 *
 * @param {number} year - integer for what year we want to start our dates from
 */
export const monthsSince = (year = 2003) => {
  let dates = [];

  let startDate = new Date(
    `Jan 01 ${year} 16:00:00 GMT-0800 (Pacific Standard Time)`
  );
  let incase = 7;
  while (startDate.getDay() && incase) {
    startDate.setDate(startDate.getDate() - 1);
    incase -= 1;
  }
  dates.push(new Date(startDate));

  const today = new Date();

  while (new Date(dates[dates.length - 1]) < today) {
    const oldDate = new Date(dates[dates.length - 1]);
    const newDate = new Date(oldDate.setMonth(oldDate.getMonth() + 1));
    dates.push(newDate);
  }

  // In the end we could replace the first thing in the list with the first day of the first year
  dates[0] = new Date(
    `Jan 01 ${year} 16:00:00 GMT-0800 (Pacific Standard Time)`
  );

  return dates;
};

/**
 *
 * @param {number} year - integer for what year we want to start our dates from
 */
export const weeksSince = (year = 2003) => {
  let dates = [];
  // Create the first date - the sunday before it all begins, or the sunday it all begins sometimes
  let startDate = new Date(
    `Jan 01 ${year} 16:00:00 GMT-0800 (Pacific Standard Time)`
  );
  let incase = 7;
  while (startDate.getDay() && incase) {
    startDate.setDate(startDate.getDate() - 1);
    incase -= 1;
  }
  dates.push(new Date(startDate));

  const today = new Date();

  while (new Date(dates[dates.length - 1]) < today) {
    const oldDate = new Date(dates[dates.length - 1]);
    const newDate = new Date(oldDate.setDate(oldDate.getDate() + 7));
    dates.push(newDate);
  }

  // In the end we could replace the first thing in the list with the first day of the first year
  dates[0] = new Date(
    `Jan 01 ${year} 16:00:00 GMT-0800 (Pacific Standard Time)`
  );

  return dates;
};

/**
 * Takes in the first day of the quarter and returns the last day of the quarter
 *
 * @param {date} startDate  - first day of a quarter, e.g. new Date(`Wed Jan 01 2003 16:00:00 GMT-0800 (Pacific Standard Time)`)
 */
export const getQuarterEndDate = (startDate) => {
  let endDate = new Date(startDate);

  endDate.setMonth(endDate.getMonth() + 3);

  endDate.setDate(endDate.getDate() - 1);

  return endDate;
};

// Gets the number of quarters since Jan 1 2003 (Feb 1 2003 would be 0)
export const getQuarterFromMonth = (startDate) => {
  let newDate = new Date(startDate);
  const startYear = newDate.getFullYear();
  const startMonth = newDate.getMonth();
  const quarterIndex = (startYear - 2003) * 4 + Math.floor(startMonth / 3);
  return quarterIndex;
};

/**
 * inserting parameters into the url
 *
 * @param {string} key  - variable to set, ie lng
 * @param {string} value - value of that key, ie -121
 * @param {string} dir - which visualization to point to, ie arbo or invasive
 * @param {boolean} reload - whether we just reloaded, important for print quality
 */
export const insertParam = (key, value, dir, reload = false) => {
  key = encodeURI(key);
  value = encodeURI(value);
  dir = dir || "/";
  const kvp = document.location.search.substr(1).split("&");

  let i = kvp.length;
  let x;
  while (i--) {
    x = kvp[i].split("=");

    if (x[0] === key) {
      x[1] = value;
      kvp[i] = x.join("=");
      break;
    }
  }

  if (i < 0) {
    kvp[kvp.length] = [key, value].join("=");
  }
  const searchStr = kvp.join("&");
  if (reload) document.location.search = kvp.join("&");
  window.history.pushState(null, null, `/${dir}/?${searchStr}`);
};

/**
 *
 * @param {string} key - which variable to delete, ie lng
 * @param {string} dir - which visualization to ref, ie arbo
 */
export const deleteParam = (key, dir) => {
  const kvp = document.location.search.substr(1).split("&");
  for (let i = 0; i < kvp.length; i++) {
    if (kvp[i].split("=")[0] === key) {
      kvp.splice(i, 1);
    }
  }
  const searchStr = kvp.join("&");
  window.history.pushState(null, null, `/${dir}/?${searchStr}`);
};

/**
 *
 * @param {string} str -- the reference to the text we want to copy
 */
export const copyToClipboard = (str) => {
  const el = document.createElement("textarea");
  el.value = str;
  el.setAttribute("readonly", "");
  el.style.position = "absolute";
  el.style.left = "-9999px";
  document.body.appendChild(el);
  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);
};

/**
 *
 * @param {Object} e - click event
 * @param {function} closeModal - function to close the modal
 */
/* Checks whether a click should close the modal */
export const handleClickOutsideModal = (e, closeModal) => {
  let { target } = e;
  let canClose = false;
  let count = 0; /* Don't let it check forever. Should never be more than 10 nodes */

  while (!canClose && count < 10) {
    /* Traverse up through nodes until finding the container or the card (or going up 10 levels)*/
    if (
      !target ||
      (target?.className?.indexOf &&
        typeof target.className.indexOf === "function" &&
        target.className.indexOf("main-container") !== -1)
    ) {
      /* Target would be null at top of tree. If they get that far this is the least of our worries */
      canClose = true; /* Can close the modal if the background was clicked */
    } else if (
      target?.className?.indexOf &&
      typeof target.className.indexOf === "function" &&
      target.className.indexOf("modal-card") !== -1
    ) {
      count = 99; /* Don't close the modal if the card was clicked */
    } else {
      target = target.parentNode; /* If neither case, go up the tree */
      count += 1;
    }
  }
  if (canClose) {
    closeModal();
  }
};

// taken from https://stackoverflow.com/questions/6117814/get-week-of-year-in-javascript-like-in-php
export const getWeekNumber = (d) => {
  // convert to date in case a string was accidentally passed
  const dateCopy = new Date(d);
  // Validate the date
  if (!isValidDate(dateCopy)) {
    console.error("bad dates:", d);
    return 1;
  }
  // Copy date so don't modify original
  let newD = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
  // Set to nearest Thursday: current date + 4 - current day number
  // Make Sunday's day number 7
  newD.setUTCDate(newD.getUTCDate() + 4 - (newD.getUTCDay() || 7));
  // Get first day of year
  var yearStart = new Date(Date.UTC(newD.getUTCFullYear(), 0, 1));
  // Calculate full weeks to nearest Thursday
  var weekNo = Math.ceil(((newD - yearStart) / 86400000 + 1) / 7);

  return weekNo;
};

const isValidDate = (d) => {
  return d instanceof Date && !isNaN(d);
};

export const updateArboData = (features, dates) => {
  const minDate = new Date(dates[0]),
    maxDate = new Date(dates[1]);

  features = features.map((f) => {
    let observations = 0;

    f.properties.collections.forEach((c) => {
      const cKey = Object.keys(c)[0];
      let cDate = cKey.split(" ").join(" 15 ");
      cDate = new Date(cDate);
      const collections = c[cKey];
      if (cDate > minDate && cDate < maxDate) {
        observations += collections;
      }
    });
    f.properties.observations = observations;
    return f;
  });
  return features;
};

// @shawn this should not be here, should be in config
export const MAPBOX_TOKEN =
  "pk.eyJ1IjoiY2Fsc3VydiIsImEiOiJjanZzaHEyNDgycGY1M3lxanViMnVuamtsIn0.I-nVDhFbqYBAoNv-HzlXsQ";

/**
 *
 * @param {number} num - take a zika/dengue number and return a color associated
 */
export const labelZikaDengueRisk = (num) => {
  if (num >= 2.0) {
    return colors["dark-red"];
  } else if (num >= 1.0) {
    return colors["light-red"];
  } else if (num >= 0.5) {
    return colors["light-blue"];
  } else return colors["dark-blue"];
};

/**
 * Returns array of years from param year up to current year, inclusive, or null if invalid
 *
 * @param {number} year - starting year
 * @returns {number[] | null} - array of years or null if year invalid
 */
export const yearsSince = (year) => {
  let currentYear = new Date().getFullYear();
  if (typeof year !== "number" || year > currentYear) return null;

  const years = [currentYear];
  while (currentYear > year) {
    currentYear--;
    years.push(currentYear);
  }
  return years;
};

const beginningOfAbundanceData = yearsSince(1952);
export const abundanceDWeeks = diseaseWeeks.filter((d) => {
  return beginningOfAbundanceData.indexOf(d.disease_year) > -1;
});

export const abundanceDW = {};
abundanceDWeeks.forEach((d) => {
  const year = d.disease_year;
  const week = d.disease_week;
  const date = d.end_date;

  if (!abundanceDW[year]) {
    abundanceDW[year] = {};
  }
  abundanceDW[year][week] = date;
});

// Array filled from 1 to 52 that can be cloned for easy use straight off SO
export const array52 = Array.from({ length: 52 }, (_, i) => i + 1);

/**
 * Get disease week from date
 * @param {Date} date - date to get disease week for, defaults to now
 * @returns {Number} - Disease week of input date, or of now if no date entered
 */
export const getDiseaseWeekFromDate = (date = new Date()) => {
  const yearStart = new Date(date.getFullYear(), 0, 1);
  // get first sunday as disease weeks are so based
  const firstSunday =
    yearStart.getDay() === 0
      ? yearStart
      : new Date(yearStart.getFullYear(), 0, 1 + (7 - yearStart.getDay()));
  const currentDate = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate()
  );
  // Time between first sunday and date entered
  const diffTime = Math.abs(currentDate - firstSunday);
  // Turn that into number of days
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  // Divide by 7 and ceiling for disease week number
  return Math.ceil(diffDays / 7);
};

/**
 * Get the start and end dates of a CDC disease week for a given year and week number
 * @param {number} year - The epi year
 * @param {number} weekNumber - The CDC disease week number
 * @returns {Object} - An object containing the start and end dates of the disease week as ISO strings
 */
export const getDiseaseWeekDates = (year, weekNumber) => {
  // Start with January 1st
  const jan1 = new Date(year, 0, 1);

  // Find the first Sunday of January in year
  const firstSunday = new Date(jan1);
  firstSunday.setDate(jan1.getDate() + (7 - jan1.getDay()));

  // Calculate the first day of the disease week
  const startDay = new Date(firstSunday);
  startDay.setDate(firstSunday.getDate() + (weekNumber - 1) * 7);

  // If January 1 is Sun, Mon, Tues, or Wed, adjust the start day
  if (jan1.getDay() < 4) {
    startDay.setDate(startDay.getDate() - 7);
  }

  // Calculate the last day of the disease week
  const endDay = new Date(startDay);
  endDay.setDate(startDay.getDate() + 6);

  return {
    start_date: startDay.toISOString(),
    end_date: endDay.toISOString(),
  };
};

/**
 * Get the number of CDC disease weeks for a given year
 * @param {number} year - The epi year
 * @returns {number} - The number of disease weeks in that year
 */
export const getNumberOfDiseaseWeeks = (year) => {
  const lastWeek = getDiseaseWeekDates(year, 52);

  // Get the last saturday of the 52nd dw week of the year
  const lastSaturdayOfYear = new Date(lastWeek.end_date);
  // Get the Sunday of the week after that. Now lastSaturday is lastSunday
  lastSaturdayOfYear.setDate(lastSaturdayOfYear.getDate() + 1);

  // If the next Sunday is in the same year, it's a 53 weeker
  if (lastSaturdayOfYear.getFullYear() === year) {
    return 53;
  }

  // Default condition
  return 52;
};

/**
 * truncate a string if necessary, allow given char limit
 * @param {string} str - String with length to be capped
 * @param {number} maxChars - Maximum number of string characters to be returned
 * @returns {string} - The first n = maxChars characters of str, followed by '...'
 */
export const truncateStr = (str, maxChars) => {
  if (str.length < maxChars) {
    return str;
  } else {
    return str.slice(0, maxChars + 1) + "...";
  }
};

/**
 * Filters the keys of a map based on their corresponding values
 *
 * @param {Object} [map={}] - map to filter
 * @returns {string[]} - array of filtered keys
 */
export const filterMapKeys = (map = {}) => {
  return Object.keys(map).filter((key) => map[key]);
};

/**
 * Recursive helper function to get nested object depth
 * It's a separate function for now because it was confusing but idk that it needs to be
 * @param {object} obj - object to calculate the depth of
 * @returns {number} depth of nested objects within the input object
 */
function calculateDepth(obj) {
  let level = 1;
  for (let key in obj) {
    if (!obj.hasOwnProperty(key)) continue;

    if (typeof obj[key] === "object") {
      const depth = calculateDepth(obj[key]) + 1;
      level = Math.max(depth, level);
    }
  }
  return level;
}

/**
 * Gets depth of nested objects within the provided object
 * Translates and decodes coordinatesfrom polylines for the map
 *
 * @param {object} obj - object to calculate the depth of
 * @returns {number} depth of nested objects within the input object
 */
function depthOf(object) {
  return calculateDepth(object);
}

/**
 * Recursively decodes coordinates from a given object
 * handles both single points and arrays of coordinates
 *
 * @param {Object|Array} coordinates - coordinates to decode
 * @returns {Array} decoded coordinates
 */
function _decodeCoordinates(coordinates) {
  if (typeof coordinates.point === "undefined") {
    if (coordinates.constructor === Array) {
      const container = [];
      for (let i in coordinates) {
        const g = _decodeCoordinates(coordinates[i]);
        container.push(g);
      }
      return container;
    }
  } else {
    const pointContainer = [];
    // Wrapped in if statement after some errors were occurring in polyline encoding 2.3.2022
    if (coordinates.point) {
      const points = polyUtil.decode(coordinates.point);
      for (let pointIndex in points) {
        pointContainer.push([points[pointIndex][1], points[pointIndex][0]]);
      }
    }
    return pointContainer;
  }
}

/**
 * Decodes geometry object by updating its coordinates from polyline and type to either "Polygon" or "MultiPolygon"
 * based on depth of the new coordinates
 * @param {object} g - The geometry object to be decoded
 * @returns {object} The updated geometry object with type set to "Polygon" or "MultiPolygon"
 */
export function decodeGeometry(g) {
  const newCoordinates = _decodeCoordinates(g.coordinates);
  if (depthOf(newCoordinates) === 3) {
    g.type = "Polygon";
  } else {
    g.type = "MultiPolygon";
  }
  g.coordinates = newCoordinates;
  return g;
}

/**
 * Get user coordinates from browser. Based on https://blog.larapulse.com/es-2015/synchronous-fetch-browser-geolocation
 * @param {Object} options
 * @returns {Object} such that {coords, timestamp}
 */
export function getCurrentPosition(options = {}) {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(resolve, reject, options);
  });
}

/**
 * Wrapper for getting coordinates from browser
 * @returns {Object} Such that {latitude, longitude, accuracy}
 */
export const fetchCoordinates = async () => {
  try {
    const position = await getCurrentPosition();
    const { coords } = position;
    return coords;
  } catch (err) {
    console.error(err);
  }
};

/**
 * Gets user's location via the browser's coordinates
 * @returns {string} - Acronym for usState, such that 'CA' or 'OK'
 */
export const getLocation = async () => {
  // Default to localstorage
  let usState = localStorage.getItem("usState");
  if (usState && validStateAbbreviations.includes(usState)) {
    // Return the state from localstorage if it exists and is valid
    return usState;
  }
  // if nothing in localstorage, or localstorage wasn't a valid state, default to CA
  if (!usState) {
    usState = "CA";
  }
  try {
    const coordinates = await fetchCoordinates();
    // If coordinates failed, skip call
    if (coordinates) {
      if (coordinates.accuracy > 6000) {
        // Accuracy is like margin of error, if too far off just use default and avoid db call
        console.log("Location accuracy low. Default used.");
        return usState;
      }
      const res = await axios.get(
        //`http://localhost:8011/v2/location/${coordinates.longitude}/${coordinates.latitude}/${usState}`
        `https://mathew.vectorsurv.org/v2/location/${coordinates.longitude}/${coordinates.latitude}/${usState}`
      );
      const stateCode = res.data.usState;
      console.log("geocoded statecode is", stateCode);
      if (stateCode in mapCenters) {
        usState = stateCode;
      } else {
        console.log("Geolocation not a valid state, default used instead");
        usState = correctestState[stateCode];
      }
    } else {
      console.log("Location denied, default used instead");
    }
  } catch (err) {
    console.error("Could not verify location, default used instead", err);
  }
  return usState;
};

// HI is correct values but nothing on map as yet
export const validStateAbbreviations = [
  "AZ",
  "CA",
  "CO",
  // "HI",
  "ID",
  "LA",
  "NC",
  "ND",
  "NE",
  "NH",
  "NJ",
  "NV",
  "NY",
  "OH",
  "OK",
  "OR",
  "PI",
  "SD",
  "TN",
  "TX",
  "UT",
  "VA",
  "WA",
  "WY",
];

export const validStates = [
  "Arizona",
  "California",
  "Colorado",
  // "Hawaii",
  "Idaho",
  "Louisiana",
  "Nebraska",
  "North Carolina",
  "North Dakota",
  "New Hampshire",
  "New Jersey",
  "New York",
  "Nevada",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "USAPI",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Virginia",
  "Washington",
  "Wyoming",
];

export const mapCenters = {
  AZ: { center: [-111.95, 34.23], zoom: 5.6 },
  CA: { center: [-119.0, 36.95], zoom: 5.4 },
  CO: { center: [-105.56, 39.21], zoom: 5.7 },
  // HI: { center: [-155.54, 20.88], zoom: 6.26 },
  ID: { center: [-114.52, 45.53], zoom: 5.51 },
  KY: { center: [-85.75, 37.4], zoom: 6.35 },
  LA: { center: [-91.831, 30.924], zoom: 6.157 },
  NC: { center: [-79.825, 35.052], zoom: 5.63 },
  ND: { center: [-100.43, 47.84], zoom: 6.61 },
  NE: { center: [-100, 40.66], zoom: 5.49 },
  NH: { center: [-71.61, 44], zoom: 6.87 },
  NJ: { center: [-74.52, 40.14], zoom: 7.0 },
  NV: { center: [-117.33, 38.99], zoom: 5.7 },
  NY: { center: [-73.106, 40.849], zoom: 7.58 },
  OH: { center: [-82.7, 40.28], zoom: 6.3 },
  OK: { center: [-98.33, 35.8], zoom: 5.7 },
  OR: { center: [-120.76, 44.22], zoom: 5.97 },
  PI: {
    center: [150.9611488823266, 11.017254687570201],
    zoom: 3.8284747049893344,
  },
  SD: { center: [-100.334, 44.42], zoom: 6.64 },
  TN: { center: [-85.886, 35.25], zoom: 5.691 },
  TX: { center: [-99.67, 31.29], zoom: 5.13 },
  UT: { center: [-111.58, 39.67], zoom: 6.1 },
  VA: { center: [-79.79, 37.85], zoom: 6.032 },
  WA: { center: [-120.67, 47.1], zoom: 5.7 },
  WY: { center: [-107.466, 43.0], zoom: 5.75 },
};

export const mobileMapCenters = {
  AZ: { center: [-111.93, 34.75], zoom: 4.85 },
  CA: { center: [-118.8, 38.36], zoom: 4.3 },
  CO: { center: [-105.59, 39.0], zoom: 4.9 },
  // HI: { center: [-157.69, 21.28], zoom: 4.88 },
  ID: { center: [-114.43, 46.19], zoom: 4.23 },
  KY: { center: [-85.8, 37.6], zoom: 5 },
  LA: { center: [-91.78, 31.25], zoom: 5.28 },
  NC: { center: [-80.02, 36.46], zoom: 4.69 },
  ND: { center: [-100.33, 47.0], zoom: 4.84 },
  NE: { center: [-99.67, 42.06], zoom: 4.6 },
  NH: { center: [-71.54, 44.12], zoom: 5.97 },
  NJ: { center: [-74.58, 40.35], zoom: 6.0 },
  NV: { center: [-116.15, 39.02], zoom: 4.5 },
  NY: { center: [-73.156, 40.96], zoom: 6.2 },
  OH: { center: [-82.7, 40.28], zoom: 5.7 },
  OK: { center: [-98.33, 35.8], zoom: 4.5 },
  OR: { center: [-120.51, 44.24], zoom: 4.99 },
  PI: {
    center: [150.9611488823266, 11.017254687570201],
    zoom: 3.8284747049893344,
  },
  SD: { center: [-100.62, 45.28], zoom: 4.72 },
  TN: { center: [-86.03, 36.69], zoom: 4.55 },
  TX: { center: [-99.76, 32.52], zoom: 4.19 },
  UT: { center: [-111.84, 39.84], zoom: 4.97 },
  VA: { center: [-79.79, 38.27], zoom: 5 },
  WA: { center: [-120.32, 47.7], zoom: 4.85 },
  WY: { center: [-107.62, 43.52], zoom: 4.93 },
};

const correctestState = {
  AL: "TN",
  AK: "CA",
  AZ: "AZ",
  AR: "TN",
  CA: "CA",
  CO: "CO",
  CT: "NJ",
  DE: "NJ",
  FL: "NC",
  GA: "NC",
  HI: "HI",
  ID: "ID",
  IL: "TN",
  IN: "TN",
  IA: "NE",
  KS: "NE",
  KY: "TN",
  LA: "LA",
  ME: "NJ",
  MD: "NJ",
  MA: "NJ",
  MI: "NJ",
  MN: "SD",
  MS: "TN",
  MO: "NE",
  MT: "NE",
  NE: "NE",
  NH: "NH",
  NJ: "NJ",
  NM: "AZ",
  NV: "NV",
  NY: "NY",
  NC: "NC",
  ND: "ND",
  PI: "PI",
  OH: "OH",
  OK: "OK",
  OR: "OR",
  PA: "NJ",
  RI: "NJ",
  SC: "NC",
  SD: "SD",
  TN: "TN",
  TX: "TX",
  UT: "UT",
  VT: "TN",
  VA: "VA",
  WA: "WA",
  WV: "NC",
  WI: "NE",
  WY: "WY",
};

// How to initialize the map center and coords.
// First look to the URL, then look to local storage's mapCenter values on line
export const initMapOptions = (options) => {
  const { urlParams, usState } = options;
  if (isValidParamCoords(urlParams)) {
    return {
      center: [+urlParams.lng, +urlParams.lat],
      zoom: +urlParams.zoom || 7.0,
    };
  } else {
    return isMobileDevice()
      ? mobileMapCenters[usState] || mobileMapCenters["CA"]
      : mapCenters[usState] || mapCenters["CA"];
  }
};

/**
 * Checks lng and lat values from URL params for validity
 * @param {Object} urlParams
 * @returns bool valid
 */
export const isValidParamCoords = (urlParams) => {
  let { lat, lng } = urlParams;
  lat = +lat;
  lng = +lng;
  return (
    !isNaN(lat) &&
    !isNaN(lng) &&
    -90 < lat &&
    lat < 90 &&
    -180 < lng &&
    lng < 180
  );
};

// Default start date should be the beginning of last year if today is before July. Otherwise it should be the beginning of this year.
const getDefaultDateRange = (dateArray) => {
  let endIdx = dateArray.length - 1;
  let startIdx = dateArray.length - 2;

  let showLastYear = false;

  const today = new Date();
  if (today.getMonth() < 6) {
    // for now, it will roll over in July
    showLastYear = true;
  }

  const targetYear = showLastYear
    ? today.getFullYear() - 1
    : today.getFullYear();

  let newEnough = true;
  while (newEnough) {
    let nextStartDate = new Date(dateArray[startIdx - 1]);
    if (nextStartDate.getFullYear() < targetYear) {
      newEnough = false;
    } else {
      startIdx -= 1;
    }
  }

  return { startIdx, endIdx };
};

// Default start date should be the beginning of last year if today is before July. Otherwise it should be the beginning of this year.
const getDefaultMonthRange = (monthArray) => {
  let endIdx = monthArray.length - 1;
  let startIdx = monthArray.length - 2;

  let showLastYear = false;

  const today = new Date();
  if (today.getMonth() < 6) {
    // for now, it will roll over in July
    showLastYear = true;
  }

  const targetYear = showLastYear
    ? today.getFullYear() - 1
    : today.getFullYear();

  let newEnough = true;
  while (newEnough) {
    let nextStartDate = new Date(monthArray[startIdx - 1]);
    if (nextStartDate.getFullYear() < targetYear) {
      newEnough = false;
    } else {
      startIdx -= 1;
    }
  }

  return { startIdx, endIdx };
};

// Default start date should be the beginning of last year if today is before July. Otherwise it should be the beginning of this year.
const getDefaultWeekRange = (weekArray) => {
  let endIdx = weekArray.length - 1;
  let startIdx = weekArray.length - 2;

  let showLastYear = false;

  const today = new Date();
  if (today.getMonth() < 6) {
    // for now, it will roll over in July
    showLastYear = true;
  }

  const targetYear = showLastYear
    ? today.getFullYear() - 1
    : today.getFullYear();

  let newEnough = true;
  while (newEnough) {
    let nextStartDate = new Date(weekArray[startIdx - 1]);
    if (nextStartDate.getFullYear() < targetYear) {
      newEnough = false;
    } else {
      startIdx -= 1;
    }
  }

  return { startIdx, endIdx };
};

export const handleParamDateIndices = (start, end, dateArray) => {
  const min = 0;
  const max = dateArray.length - 1;
  let { startIdx, endIdx } = getDefaultDateRange(dateArray);

  // start must be a number.
  if (isNaN(start)) {
    // If not number, assume malicious
    return { startIdx, endIdx };
  }

  // if end is a number, sort the indices. This assumes that if they are not in order they were accidentally entered reversed
  if (!isNaN(end)) {
    startIdx = Math.min(start, end);
    endIdx = Math.max(start, end);
    if (startIdx === endIdx) {
      startIdx = endIdx - 1;
    }
  } else {
    // if end not a number, just set startIdx
    startIdx = start;
  }

  // ensure that end value is less than max
  if (!isNaN(end) && end > 0) {
    endIdx = Math.min(max, endIdx);
  } else {
    endIdx = max;
  }

  // handle negative start index
  if (start < 0) {
    startIdx = start + endIdx;
  }

  // ensure start value falls between min and max inclusive
  startIdx = Math.max(min, startIdx);

  // if start still in future, bump it back until it isn't. Avoids empty charts. Just in case.
  let overflow = 0; // overflow while loop just in case
  while (
    dateArray[startIdx].getTime() > new Date().getTime() &&
    overflow < 100
  ) {
    startIdx -= 1;
    overflow += 1; // just in case
  }

  return { startIdx, endIdx };
};

export const handleParamMonthIndices = (start, end, monthArray) => {
  const min = 0;
  const max = monthArray.length - 1;
  let { startIdx, endIdx } = getDefaultMonthRange(monthArray);

  // start must be a number.
  if (isNaN(start)) {
    // If not number, assume malicious
    return { startIdx, endIdx };
  }

  // if end is a number, sort the indices. This assumes that if they are not in order they were accidentally entered reversed
  if (!isNaN(end)) {
    startIdx = Math.min(start, end);
    endIdx = Math.max(start, end);
    if (startIdx === endIdx) {
      startIdx = endIdx - 1;
    }
  } else {
    // if end not a number, just set startIdx
    startIdx = start;
  }

  // ensure that end value is less than max
  if (!isNaN(end) && end > 0) {
    endIdx = Math.min(max, endIdx);
  } else {
    endIdx = max;
  }

  // handle negative start index
  if (start < 0) {
    startIdx = start + endIdx;
  }

  // ensure start value falls between min and max inclusive
  startIdx = Math.max(min, startIdx);

  // if start still in future, bump it back until it isn't. Avoids empty charts. Just in case.
  let overflow = 0; // overflow while loop just in case
  while (
    monthArray[startIdx].getTime() > new Date().getTime() &&
    overflow < 100
  ) {
    startIdx -= 1;
    overflow += 1; // just in case
  }

  return { startIdx, endIdx };
};

export const handleParamWeekIndices = (start, end, weekArray) => {
  const min = 0;
  const max = weekArray.length - 1;
  let { startIdx, endIdx } = getDefaultWeekRange(weekArray);

  // start must be a number.
  if (isNaN(start)) {
    // If not number, assume malicious
    return { startIdx, endIdx };
  }

  // if end is a number, sort the indices. This assumes that if they are not in order they were accidentally entered reversed
  if (!isNaN(end)) {
    startIdx = Math.min(start, end);
    endIdx = Math.max(start, end);
    if (startIdx === endIdx) {
      startIdx = endIdx - 1;
    }
  } else {
    // if end not a number, just set startIdx
    startIdx = start;
  }

  // ensure that end value is less than max
  if (!isNaN(end) && end > 0) {
    endIdx = Math.min(max, endIdx);
  } else {
    endIdx = max;
  }

  // handle negative start index
  if (start < 0) {
    startIdx = start + endIdx;
  }

  // ensure start value falls between min and max inclusive
  startIdx = Math.max(min, startIdx);

  // if start still in future, bump it back until it isn't. Avoids empty charts. Just in case.
  let overflow = 0; // overflow while loop just in case
  while (
    weekArray[startIdx].getTime() > new Date().getTime() &&
    overflow < 100
  ) {
    startIdx -= 1;
    overflow += 1; // just in case
  }

  return { startIdx, endIdx };
};

export const addDays = (date, days) => {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

export const isMobileDevice = () => {
  return /Android|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop|BlackBerry|Mobile/i.test(
    navigator.userAgent
  );
};

export const capitalizeFirstLetter = (string) =>
  typeof string === "string"
    ? string.charAt(0).toUpperCase() + string.slice(1)
    : string;

/**
 * Slugifies a given text by converting to lowercase, removing special characters, and replacing spaces with hyphens
 * @param {string} text - The text to be slugified
 * @returns {string} The slugified text
 */
export const slugifyString = (text) =>
  text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, "-") // Replace spaces with -
    .replace(/[^\w-]+/g, "") // Remove all non-word characters
    .replace(/--+/g, "-") // Replace multiple - with single -
    .replace(/^-+/, "") // Trim - from start of text
    .replace(/-+$/, ""); // trim - from end of text

// auth stuff borrowed with love from vs gui
export const getAuthTokenByCookie = async () => {
  let token = "";
  const cookies = new Cookies();
  const { username, authtoken, loginid } = cookies.getAll();

  const baseURL = process.env.REACT_APP_API_BASE_URL;
  const url = `${baseURL}/login`;
  try {
    const response = await axios.post(url, {
      username,
      loginid,
      authtoken,
    });

    token = response.data.token;

    return token;
  } catch (error) {
    console.error("Error:", error);
    return token;
  }
};

export const getAuthTokenByENV = async () => {
  const baseURL = process.env.REACT_APP_SANDBOX_URL;
  const password = process.env.REACT_APP_password;
  const username = process.env.REACT_APP_username;

  try {
    const response = await axios.post(`${baseURL}/login`, {
      username,
      password,
    });

    const { token } = response.data;

    return token;
  } catch (error) {
    console.error("Error:", error.message);
  }
};

export const fetchPage = async ({ url, queryConfig, token }) => {
  return axios.get(`${url}?${queryString.stringify(queryConfig)}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const fetchMultiplePages = async ({
  url,
  queryConfig,
  token,
  totalPages,
  startPage = 2,
  maxPages,
}) => {
  const data = [];
  const total = maxPages ? Math.min(maxPages, totalPages) : totalPages;

  for (let i = startPage; i <= total; i++) {
    queryConfig.page = i;
    data.push(fetchPage({ url, queryConfig, token: token }));
  }
  try {
    return await Promise.all(data);
  } catch (error) {
    console.error("error fetching multiple pages", error);
    return data;
  }
};

export const fetchAllPages = async ({
  url,
  queryConfig,
  token,
  startPage = 1,
  maxPages,
}) => {
  try {
    queryConfig.page = startPage;
    const response = await fetchPage({ url, queryConfig, token });
    let data = response.data.rows;
    const totalPages = response.data.totalPages;
    if (totalPages > 1 && (!maxPages || maxPages > 1)) {
      const allPages = await fetchMultiplePages({
        url,
        queryConfig,
        token,
        totalPages,
        startPage: startPage + 1,
        maxPages,
      });
      data = allPages.reduce((acc, cur) => {
        acc.push(...cur.data.rows);
        return acc;
      }, data);
    }
    return { success: true, data };
  } catch (error) {
    // return handleNetworkErrorLog(error);
    console.error(error);
    return false;
  }
};

export const mapStyles = {
  Dark: "mapbox://styles/mapbox/dark-v10",
  "Light (Recommended)": "mapbox://styles/mapbox/light-v9",
  Natural: "mapbox://styles/mapbox/outdoors-v11",
  Satellite: "mapbox://styles/mapbox/satellite-v9",
  Other: "mapbox://styles/calsurv/ckfewtu2q0p4c19n9rd91mi6w",
};

export const mapStyleAgencyBoundaryColors = {
  Dark: "white",
  "Light (Recommended)": "black",
  Natural: "black",
  Satellite: "white",
  Other: "salmon",
};

// tuple such that [key, value]. This combination allows us to determine the layer directly below which our main data layer will appear
// id would be ideal but we can't count on mapbox keeping their ids the same - only do on natural because there's less room to squidge
export const layerTuplesByMapStyle = {
  Dark: ["type", "symbol"],
  "Light (Recommended)": ["type", "symbol"],
  Natural: ["id", "admin-0-boundary-disputed"],
  Satellite: ["type", "symbol"], // We want it on top
  Other: ["type", "symbol"],
};

export const monthNames = [
  "JAN",
  "FEB",
  "MAR",
  "APR",
  "MAY",
  "JUN",
  "JUL",
  "AUG",
  "SEP",
  "OCT",
  "NOV",
  "DEC",
];

export const countyCodeNames = {
  "01": "Alameda",
  "02": "Alpine",
  "03": "Amador",
  "04": "Butte",
  "05": "Calaveras",
  "06": "Colusa",
  "07": "Contra Costa",
  "08": "Del Norte",
  "09": "El Dorado",
  10: "Fresno",
  11: "Glenn",
  12: "Humboldt",
  13: "Imperial",
  14: "Inyo",
  15: "Kern",
  16: "Kings",
  17: "Lake",
  18: "Lassen",
  19: "Los Angeles",
  20: "Madera",
  21: "Marin",
  22: "Mariposa",
  23: "Mendocino",
  24: "Merced",
  25: "Modoc",
  26: "Mono",
  27: "Monterey",
  28: "Napa",
  29: "Nevada",
  30: "Orange",
  31: "Placer",
  32: "Plumas",
  33: "Riverside",
  34: "Sacramento",
  35: "San Benito",
  36: "San Bernardino",
  37: "San Diego",
  38: "San Francisco",
  39: "San Joaquin",
  40: "San Luis Obispo",
  41: "San Mateo",
  42: "Santa Barbara",
  43: "Santa Clara",
  44: "Santa Cruz",
  45: "Shasta",
  46: "Sierra",
  47: "Siskiyou",
  48: "Solano",
  49: "Sonoma",
  50: "Stanislaus",
  51: "Sutter",
  52: "Tehama",
  53: "Trinity",
  54: "Tulare",
  55: "Tuolumne",
  56: "Ventura",
  57: "Yolo",
  58: "Yuba",
};

/**
 * Map from state abbreviations to full state names
 * @type {Object.<string, string>}
 */
const stateNames = {
  AL: "Alabama",
  AK: "Alaska",
  AS: "American Samoa",
  AZ: "Arizona",
  AR: "Arkansas",
  CA: "California",
  CO: "Colorado",
  CT: "Connecticut",
  DE: "Delaware",
  DC: "District Of Columbia",
  FM: "Federated States Of Micronesia",
  FL: "Florida",
  GA: "Georgia",
  GU: "Guam",
  HI: "Hawaii",
  ID: "Idaho",
  IL: "Illinois",
  IN: "Indiana",
  IA: "Iowa",
  KS: "Kansas",
  KY: "Kentucky",
  LA: "Louisiana",
  ME: "Maine",
  MH: "Marshall Islands",
  MD: "Maryland",
  MA: "Massachusetts",
  MI: "Michigan",
  MN: "Minnesota",
  MS: "Mississippi",
  MO: "Missouri",
  MT: "Montana",
  NE: "Nebraska",
  NV: "Nevada",
  NH: "New Hampshire",
  NJ: "New Jersey",
  NM: "New Mexico",
  NY: "New York",
  NC: "North Carolina",
  ND: "North Dakota",
  MP: "Northern Mariana Islands",
  OH: "Ohio",
  OK: "Oklahoma",
  OR: "Oregon",
  PW: "Palau",
  PA: "Pennsylvania",
  PI: "US Pacific Islands",
  PR: "Puerto Rico",
  RI: "Rhode Island",
  SC: "South Carolina",
  SD: "South Dakota",
  TN: "Tennessee",
  TX: "Texas",
  UT: "Utah",
  VT: "Vermont",
  VI: "Virgin Islands",
  VA: "Virginia",
  WA: "Washington",
  WV: "West Virginia",
  WI: "Wisconsin",
  WY: "Wyoming",
};

/**
 * get full name of a state given its abbreviation
 * @param {string} stateAbbreviation - abbreviation of the state
 * @returns {string} full name of state, or 'Invalid State' if the invalid
 */
export const getStateName = (stateAbbreviation) => {
  return stateNames[stateAbbreviation] || "Invalid State";
};

export const complexes = [
  "All Species",
  "Cx pipiens+quinquefasciatus",
  "Cx pipiens+quinquefasciatus+restuans",
  "Cx pipiens+restuans+salinarius",
];

export const vectorSurvLogo = `
  <div class="vectorsurv-logo mapboxgl-ctrl">
    <a style="display:flex;justify-items:center;align-items:center;text-decoration:none;color:#000;" href="https://vectorsurv.org" target="_blank" rel="noopener nofollow">
      <img src=${logo} height="24">
    </a>
  </div>`;
